import { Button, Divider, Space, Typography } from 'antd'
import useSettingsStore from '../manager/settings-store'
import GlobalHistoryModal from 'src/components/GlobalHistoryModal'

const SelectedSettingsTitle = () => {
  const { Title } = Typography
  const selectedSettings = useSettingsStore((state) => state.selectedSettings)
  const isHistoryEnabled = useSettingsStore((state) => state.isHistoryEnabled)

  return (
    <>
      <Space
        style={{ display: 'flex', justifyContent: 'space-between' }}
        data-testid='selected-settings-title'
      >
        <Title level={2} style={{ marginBottom: 0 }}>
          {selectedSettings}
        </Title>
        {isHistoryEnabled && (
          <GlobalHistoryModal
            modalTitle='Audit Trail'
            overrideMainButton={<Button>Audit Trail</Button>}
          />
        )}
      </Space>
      <Divider />
    </>
  )
}

export default SelectedSettingsTitle
